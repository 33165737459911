<template>
<div class="prodcont">
    <h3>同城信息</h3>
    <el-row class="products" gutter="6">
        <el-col :span="6" v-for="(item,index) in products" :key="index">
            <div class="prods" @click="showCurrent(item)">
                <h5>{{item.MerchantPublish_Name}}</h5>
                <p>{{item.MerchantPublish_Content}}</p>
                <div class="time">{{item.MerchantPublish_Create}}</div>
            </div>
        </el-col>
    </el-row>
    <el-dialog title="信息详情" v-model="detailVisible" width="50%">
        <p>{{current.MerchantPublish_Content}}</p>
        <p class="time">来自：{{current.Merchant_Name}}</p>
    </el-dialog>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="setPageIndx">
    </el-pagination>
</div>
</template>

<script>
let app = ""
export default {
    data() {
        return {
            domin: "",
            total: 0,
            detailVisible:false,
            current:"",
            pageindex: 1,
            products: []
        }
    },
    created() {
        app = this.$.appContext.app
        this.domin = app.domin
        console.log(app.domin)
        this.pullList()
    },
    methods: {
        showCurrent(item){
            this.current = item;
            this.detailVisible = true

        },
        setPageIndx(pgindx) {
            this.pageindex = pgindx;
            this.pullList();
        },
        jump(id) {
            this.$router.push({
                path: '/details/' + id
            })
        },
        pullList() {
            app.wpost({
                url: "/AdminAjax/MerchantPublish.ashx?type=select",
                data: {
                    pageindex: this.pageindex,
                    pagesize: 12
                },
                succ: (data) => {
                    console.log("res", data)
                    this.products = data.list
                },
            });
        }
    }

}
</script>


<style scoped>
.time{
    font-size: 12px;
    text-align: right;
    color: #666
}
.el-pagination {
    margin: 20px 0;
    text-align: center
}

.prodcont {
    padding: 20px;
}

.prodcont h3 {
    color: #F56C6C;
}

.el-carousel {
    text-align: center;
    background-color: #fafafa;
    margin: 20px auto;
}

.products {
    box-sizing: border-box;
    padding: 30px 10px;
    margin: 20px auto;
}
.products .el-col{
    padding: 20px 12px;
    

}

.products .el-col:hover {
    cursor: pointer;
}
.prods:hover{
    background-color: #fafafa;
    box-shadow:  0 0 12px rgba(0, 0, 0, .3)
}

.prods{
    margin: 12px;
    border: 1px solid #dedede;
    padding: 12px;
    border-radius: 6px;
}
.prods p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>
